<template>
    <div>
        <div v-if="success">
            <Success />
        </div>
        <div v-else>
            <div v-show="!loading" class="side-by-side" v-for="item in modules ">
                <div class="molded">
                    <div class="text-center div-icon">
                        <i :class="item.icon"></i>
                    </div>
                    <div class="text-center title div-title">
                        {{ item.title }}
                    </div>
                    <div class="text-center title div-description">
                        {{ item.description }}
                    </div>
                    <div class="div-already-has" v-if="hasRule(item.name)">
                        <div class="icon-check text-center">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <br />
                    </div>
                    <div v-else>
                        <div v-if="item.type == 'only'" class="div-quantity">
                            <IncrementDecrement :name="item.name" :triggerEvent="true" :min="1" :max="10"
                                v-model="item.quantity" />
                        </div>
                        <div v-else class="text-center">
                            <SelectStatic fieldTarget="timeZone" :data="item.options" :initialValue="item.valueOption"
                                v-model="item.valueOption" />
                        </div>
                        <div class="text-center title div-value">
                            <span v-if="item.type == 'only'">
                                <span>{{ item.value | currency }}</span>
                                <span v-if="item.name == 'User'"> mensal</span>
                                <span v-else> único</span>
                            </span>
                            <span v-else>
                                {{ item.valueOption.id | currency }} mensal
                            </span>
                        </div>
                        <div class="text-center div-btn">
                            <Button :_key="item.name" :params="{
                                term: { name: item.name, ruleId: item.ruleId, quantity: item.quantity, description: item.description, monthly: item.monthly, quantity: item.quantity, value: item.value, valueOption: item.valueOption }
                            }" title="Contratar" :backGroundColor="item.btnColor" color="white" :clicked="openTerm"
                                size="small" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Loading :center="true" v-show="loading" />

        <Modal title="Termos e Condições" :width="950" :height="750" v-if="showModal('term')">
            <Term :term="term" />
        </Modal>
    </div>
</template>

<script>

import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Term from './Term.vue'
import Success from './Success.vue'

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "Store",
    components: {
        IncrementDecrement, SelectStatic, Modal, Button, Loading, Term, Alert, Success
    },
    data() {
        return {
            urlGetPlan: "/api/v1/adm/company/get-plan",
            currentUser: 0,
            currentPrice: 0,
            loading: true,
            success: false,
            prices: {
                1: 250,
                2: 390,
                3: 490,
                4: 570,
                5: 630,
                6: 680,
                7: 730,
                8: 780,
                9: 830,
                10: 880,
                11: 930,
                12: 980,
                13: 1030,
                14: 1130,
                15: 1180,
                16: 1230,
                17: 1280,
                18: 1330,
                19: 1380,
                20: 1430,
            },
            modules: [
                {
                    name: "Signature",
                    ruleId: "6E3F2FA1-CC8E-4541-9CF9-2201D7ECF56D",
                    title: "Assinatura Digital",
                    description: "Módulo assinatura digital",
                    icon: "fa-solid fa-signature",
                    type: "options",
                    monthly: true,
                    quantity: 0,
                    value: 60,
                    valueOption: { content: '30 Assinaturas por mês', total: 30, id: '60' },
                    options: [
                        { content: '30 Assinaturas por mês', total: 30, id: '60' },
                        { content: '60 Assinaturas por mês', total: 60, id: '102' },
                        { content: '100 Assinaturas por mês', total: 100, id: '144.50' },
                        { content: '300 Assinaturas por mês', total: 300, id: '405' },
                    ],
                    btnColor: "#38A169"
                },
                {
                    name: "Billet",
                    ruleId: "1E50120F-19D4-411C-A819-687DFB355560",
                    title: "Boleto Bancário",
                    description: "Módulo boleto",
                    icon: "fa-solid fa-barcode",
                    type: "options",
                    monthly: true,
                    quantity: 0,
                    value: 30,
                    valueOption: { content: '30 Boletos por mês', total: 30, id: '30' },
                    options: [
                        { content: '30 Boletos por mês', total: 30, id: '30' },
                        { content: '60 Boletos por mês', total: 60, id: '50' },
                        { content: '100 Boletos por mês', total: 100, id: '75' },
                        { content: '200 Boletos por mês', total: 200, id: '125' },
                        { content: '300 Boletos por mês', total: 300, id: '150' },
                    ],
                    btnColor: "#38A169"
                },
                {
                    name: "Document",
                    ruleId: "",
                    title: "Documento",
                    description: "Documento avulso",
                    icon: "fa-light fa-file-invoice",
                    type: "only",
                    monthly: false,
                    quantity: 1,
                    value: 120,
                    btnColor: "#6A86A2"
                },
                /*   {
                       name: "TaxDocument",
                       ruleId: "F12D6F30-DBB5-427C-961C-712235A3AA37",
                       title: "Nota Fiscal",
                       description: "Módulo nota fiscal",
                       icon: "fa-solid fa-receipt",
                       type: "options",
                       monthly: true,
                       quantity: 1,
                       value: 45,
                       valueOption: { content: '30 Notas por mês', total: 30, id: '45' },
                       options: [
                           { content: '30 Notas por mês', total: 30, id: '45' },
                           { content: '60 Notas por mês', total: 60, id: '67.50' },
                           { content: '100 Notas por mês', total: 100, id: '84.38' },
                           { content: '200 Notas por mês', total: 200, id: '126.56' },
                           { content: '300 Notas por mês', total: 300, id: '150' },
                       ],
                       btnColor: "#38A169"
                   },*/
                {
                    name: "User",
                    ruleId: "",
                    title: "Usuário",
                    description: "Usuário avulso",
                    icon: "fa-solid fa-user",
                    type: "only",
                    monthly: false,
                    quantity: 1,
                    value: 0,
                    btnColor: "#38A169"
                },
            ],
            term: {
                name: "",
                ruleId: "",
                description: "",
                quantity: 0,
                value: 0,
                monthly: false
            },
            rules: [],
        }
    },
    mounted() {
        this.getPlan();
    },
    computed: {
        ...mapGetters("generic", ["event", "showModal"]),
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        ...mapMutations("generic", ["openModal", "removeLoading"]),
        // precisa pegar a quantidade de usuário através do get ao invés de um possível context para atualizar em tempo real
        getPlan() {
            let params = {
                url: this.urlGetPlan,
            };
            this.getApi(params).then((response) => {
                this.currentUser = response.content.plan.numberOfUsers;
                this.rules = response.content.rules;
                this.currentPrice = this.prices[this.currentUser] || 0;
                this.calculateUser(this.currentUser + 1);
                this.loading = false;
            });
        },
        calculateDocument(quantity) {
            this.modules[2].value = quantity * 120;
        },
        calculateUser(quantity) {
            this.modules[3].value = this.prices[quantity] - this.currentPrice;
        },
        openTerm(params) {
            this.openModal("term");
            const { term } = params;
            const quantity = term.monthly ? term.valueOption.total : term.quantity;
            const period = term.monthly ? "mês" : "";
            const value = term.monthly ? term.valueOption.id : term.value;

            this.term = {
                name: term.name,
                ruleId: term.ruleId,
                quantity: term.quantity,
                description: `${term.description}, totalizando ${quantity} ${period}.`,
                monthly: term.monthly,
                value: value
            };

            this.removeLoading([term.name]);
        },
        hasRule(ruleName) {
            const rule = this.rules.find(rule => rule.name === ruleName);
            return rule ? true : false;
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "updateIncrementDecrement") {

                    if (event.data.name == "Document") {
                        this.calculateDocument(event.data.value);
                    }
                    if (event.data.name == "User") {
                        this.calculateUser(this.currentUser + event.data.value);
                    }
                }

                if (event.name == "contractedPlan") {
                    this.getPlan();
                    this.success = true;
                }

                if (event.name == "backContractedPlan") {
                    this.getPlan();
                    this.success = false;
                    this.modules[1].quantity = 1;
                    this.modules[1].value = 120;
                    this.modules[3].quantity = 1;
                }
            },
            deep: true,
        },
    },
}


</script>

<style scoped>
.molded {
    width: 270px;
    border: 1px solid #e8eaed;
    background-color: white;
    border-radius: 8px !important;
    padding: 10px;
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    margin: 20px;
}

.div-icon {
    font-size: 25px;
}

.div-title {
    font-size: 18px;
}

.div-description {
    font-size: 12px;
    color: rgb(121, 121, 121);
}

.div-quantity {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.icon-check {
    font-size: 25px;
    color: #38A169;
}

.div-already-has {
    padding-top: 85px;
}

.div-value {
    font-size: 18px;
}

.div-btn {
    margin-top: 10px;
}
</style>